.my-datepicker-input {
  height: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 12px;
  padding-right: 12px;
  margin-left: 10px;
  width: 280px;
  font-size: '24px';
  font-family: 'Figtree';
}

.css-1a2l16b-MuiModal-root-MuiDialog-root {
  z-index: 0 !important;
}

.react-datepicker__time-list-item--selected {
  background-color: #ff6600 !important;
}

.react-datepicker__day--selected,
react-datepicker__day--selected:hover {
  background-color: #ff6600 !important;
}

.react-datepicker__day--keyboard-selected {
  background-color: #ff660053;
}

.react-datepicker__day--keyboard-selected:hover {
  background-color: #ff660080;
}

.react-datepicker__time-list-item--disabled {
  display: none;
}

.css-i4bv87-MuiSvgIcon-root {
  display: none !important;
  opacity: 0;
}

.css-ptiqhd-MuiSvgIcon-root {
  display: none !important;
}
.MuiPaper-root {
  min-width: 350px !important;
}
.custom-snackbar {
  top: 7rem !important;
}

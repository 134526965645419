.forgot {
  color:#FF7D26;
  font-family:'Figtree';
  cursor: 'pointer';
  font-size: 11px;
  cursor: pointer;

}

.forgot:hover {
  text-decoration: underline;
}
